<template>
    <div>
        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title">
                    <h3 class="card-label">
                        {{$t('user_settings.change_email_password')}}
                        <span class="text-muted pt-2 font-size-sm d-block"></span>
                    </h3>
                </div>
                <div class="card-toolbar">
                    <!--begin::Button-->
                    <!--          <router-link to="/dashboard" class="btn btn-light font-weight-bolder"><v-icon>mdi-chevron-left</v-icon> Back</router-link>-->
                    <!--end::Button-->
                </div>
            </div>

            <div class="card-body">

              <b-tabs content-class="mt-3">
                <b-tab :title="$t('user_settings.change_password')" active>
                  <b-card-body class="pr-0 pl-0">
                    <div class="form-group row">
                      <div class="col-lg-12 mb-5">
                        <label>{{$t('user_settings.current_password')}}<span class="text-danger">*</span></label>
                        <div class="input-group">
                          <input type="password" class="form-control" v-model="data.current_password" :class="validation && validation.current_password ? 'is-invalid' : ''" :placeholder="$t('user_settings.current_password')"/>
                          <span v-if="validation && validation.current_password" class="fv-plugins-message-container invalid-feedback">{{ validation.current_password[0] }}</span>
                        </div>
                      </div>
                      <div class="col-lg-12 mb-5">
                        <label>{{$t('user_settings.new_password')}}<span class="text-danger">*</span></label>
                        <div class="input-group">
                          <input type="password" v-model="data.new_password" class="form-control" :class="validation && validation.new_password ? 'is-invalid' : ''" :placeholder="$t('user_settings.new_password')"/>
                          <span v-if="validation && validation.new_password" class="fv-plugins-message-container invalid-feedback">{{ validation.new_password[0] }}</span>
                        </div>
                      </div>
                      <div class="col-lg-12 mb-5">
                        <label>{{$t('user_settings.confirm_password')}}<span class="text-danger">*</span></label>
                        <div class="input-group">
                          <input type="password" v-model="data.confirm_password" class="form-control" :class="validation && validation.confirm_password ? 'is-invalid' : ''" :placeholder="$t('user_settings.confirm_password')"/>
                          <span v-if="validation && validation.confirm_password" class="fv-plugins-message-container invalid-feedback">{{ validation.confirm_password[0] }}</span>
                        </div>
                      </div>

                    </div>
                  </b-card-body>
                  <div class="card-footer pl-0 pr-0">
                    <div class="row">
                      <div class="col-lg-12">
                        <button type="button" class="btn btn-primary" @click="updatePassword">{{ $t('save') }}</button>
                      </div>
                    </div>
                  </div>
                </b-tab>
                <b-tab :title="$t('user_settings.change_email')">
                  <b-card-body class="pr-0 pl-0">
                    <div class="form-group row">
                      <div class="col-lg-12 mb-5">
                        <label>{{$t('user_settings.current_password')}}<span class="text-danger">*</span></label>
                        <div class="input-group">
                          <input type="password" v-model="data.current_password" class="form-control" :class="validation && validation.current_password ? 'is-invalid' : ''" :placeholder="$t('user_settings.current_password')"/>
                          <span v-if="validation && validation.current_password" class="fv-plugins-message-container invalid-feedback">{{ validation.current_password[0] }}</span>
                        </div>
                      </div>

                      <div class="col-lg-12 mb-5">
                        <label>{{$t('user_settings.email')}}<span class="text-danger">*</span></label>
                        <div class="input-group">
                          <input type="email" v-model="data.email" class="form-control" :class="validation && validation.email ? 'is-invalid' : ''" :placeholder="$t('user_settings.email')"/>
                          <span v-if="validation && validation.email" class="fv-plugins-message-container invalid-feedback">{{ validation.email[0] }}</span>
                        </div>
                      </div>

                    </div>
                  </b-card-body>
                  <div class="card-footer pl-0 pr-0">
                    <div class="row">
                      <div class="col-lg-12">
                        <button type="button" class="btn btn-primary" @click="updateEmail">{{ $t('save') }}</button>
                      </div>
                    </div>
                  </div>
                </b-tab>

              </b-tabs>



            </div>


        </div>
        <!--end::User-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "form-user-settings",
        data() {
            return {
                mainRoute: '/',

                data: {
                    email: null,
                    current_password: null,
                    new_password: null,
                    confirm_password: null,
                },
                isEditing: false,
                validation: null,
            };
        },

        methods: {

            updateEmail() {
                ApiService.post(`/setUser`, {
                    email: this.data.email,
                    current_password: this.data.current_password,
                }).then((response) => {
                    this.validation = null;
                    this.$successAlert(response.data.message);
                }).catch((error) => {
                    this.validation = error.response ? error.response.data.errors : null;
                    this.$errorAlert(error);
                });
            },
            updatePassword() {
                ApiService.post(`/setUser`, {
                    current_password: this.data.current_password,
                    new_password: this.data.new_password,
                    confirm_password: this.data.confirm_password,
                }).then((response) => {
                    this.validation = null;
                    this.$successAlert(response.data.message);
                }).catch((error) => {
                    this.validation = error.response ? error.response.data.errors : null;
                    this.$errorAlert(error);
                });
            },

            getData() {
                ApiService.get(`/getUser`).then((response) => {
                    this.isEditing = true;
                    this.data.email = response.data.data.email;
                });
            },

        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.change_email_password")}]);
            this.getData();
        },
    };
</script>


